import React from "react"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div
        className="d-flex flex-column"
        style={{
          minHeight: "100vh",
          background: "#111111",
          fontFamily: `"Poppins", sans-serif`,
        }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

export default Layout
