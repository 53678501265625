import React, { useState } from "react"
import { songData } from "../../songData"
import { alkitabData } from "../../alkitabData"
import SongPanel from "./songPanel"

const SongAndScripturePanel = props => {
  const {
    onSelectSong,
    selectedSong,
    setSelectedLyric,
    selectedScripture,
    onSelectedScripture,
    setPreviewMode,
  } = props
  const [openSongPanel, setOpenSongPanel] = useState(true)
  const [openScripturesPanel, setOpenScripturesPanel] = useState(false)
  const [searchFieldValue, setSearchFieldValue] = useState("")
  const [searchScriptureFieldValue, setSearchScriptureFieldValue] = useState("")
  const onSearchSongTitle = e => {
    setSearchFieldValue(e.target.value)
  }
  const onSearchScripture = e => {
    setSearchScriptureFieldValue(e.target.value)
  }
  const filteredSong = songData.filter(song => {
    return song.title.toLowerCase().includes(searchFieldValue.toLowerCase())
  })

  const filteredScripture = alkitabData[0].data.filter(scripture => {
    return scripture.keyword
      .toLowerCase()
      .includes(searchScriptureFieldValue.toLowerCase())
  })

  return (
    <React.Fragment>
      <div>
        <nav className="d-flex justify-content-center">
          <button
            onClick={() => (
              setOpenSongPanel(true), setOpenScripturesPanel(false)
            )}
            className={`btn text-light mx-1 ${openSongPanel &&
              `border-warning`}`}
          >
            Songs
          </button>
          <button
            onClick={() => (
              setOpenSongPanel(false), setOpenScripturesPanel(true)
            )}
            className={`btn text-light mx-1 ${openScripturesPanel &&
              `border-warning`}`}
          >
            Scriptures
          </button>
        </nav>
        <div className="my-3" />
        {openScripturesPanel && (
          <React.Fragment>
            <form className="form-inline">
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search Scripture..."
                value={searchScriptureFieldValue}
                onChange={e => onSearchScripture(e)}
                style={{ background: "#111111", border: "none" }}
              />
            </form>
            <div className="my-3" />
            <ul
              className="list-unstyled pl-3"
              style={{
                background: "#111111",
                height: "40vh",
                overflowY: "auto",
              }}
            >
              {filteredScripture === undefined
                ? alkitabData[0].data.map((scripture, index) => {
                    return (
                      <li
                        className={`my-2 py-1 px-2 small ${selectedScripture ===
                          index && `text-light `}`}
                        style={{
                          color: "#999999",
                          cursor: "pointer",
                        }}
                        key={`${index}`}
                        onClick={() => (
                          onSelectedScripture(index),
                          setPreviewMode("scripture")
                        )}
                      >
                        {scripture.judulBuku} {scripture.bab}:{scripture.ayat} -{" "}
                        {scripture.teks.substring(0, 10)}...
                      </li>
                    )
                  })
                : filteredScripture.map((scripture, index) => {
                    return (
                      <li
                        className={`my-2 py-1 px-2 small ${selectedScripture ===
                          index && `text-light `}`}
                        style={{
                          color: "#999999",
                          cursor: "pointer",
                        }}
                        key={`${index}`}
                        onClick={() => (
                          onSelectedScripture(index),
                          setPreviewMode("scripture")
                        )}
                      >
                        {scripture.judulBuku} {scripture.bab}:{scripture.ayat} -{" "}
                        {scripture.teks.substring(0, 10)}...
                      </li>
                    )
                  })}
            </ul>
          </React.Fragment>
        )}
        {openSongPanel && (
          <SongPanel
            filteredSong={filteredSong}
            selectedSong={selectedSong}
            onSearchSongTitle={onSearchSongTitle}
            onSelectSong={onSelectSong}
            setSelectedLyric={setSelectedLyric}
            searchFieldValue={searchFieldValue}
            setPreviewMode={setPreviewMode}
          />
        )}
      </div>
    </React.Fragment>
  )
}
export default SongAndScripturePanel
