import React from "react"
import { songData } from "../../songData"

const SongPanel = ({
  filteredSong,
  selectedSong,
  onSearchSongTitle,
  onSelectSong,
  setSelectedLyric,
  searchFieldValue,
  setPreviewMode,
}) => {
  return (
    <React.Fragment>
      <form className="form-inline">
        <input
          className="form-control w-100"
          type="search"
          placeholder="Search Song Title..."
          value={searchFieldValue}
          onChange={e => onSearchSongTitle(e)}
          style={{ background: "#111111", border: "none" }}
        />
      </form>
      <div className="my-3" />
      <ul
        className="list-unstyled pl-3"
        style={{
          background: "#111111",
          height: "40vh",
          overflowY: "auto",
        }}
      >
        {filteredSong === undefined
          ? songData.map((song, index) => {
              return (
                <li
                  className={`my-2 py-1 px-2 ${selectedSong === index &&
                    `text-light `}`}
                  style={{
                    color: "#999999",
                    cursor: "pointer",
                  }}
                  key={`${index}`}
                  onClick={() => (
                    onSelectSong(index),
                    setSelectedLyric(0),
                    setPreviewMode("song")
                  )}
                >
                  {song.title}
                </li>
              )
            })
          : filteredSong.map((song, index) => {
              return (
                <li
                  className={`my-2 py-1 px-2 ${selectedSong === index &&
                    `text-light`}`}
                  style={{
                    color: "#999999",
                    cursor: "pointer",
                  }}
                  onClick={() => (
                    onSelectSong(index),
                    setSelectedLyric(0),
                    setPreviewMode("song")
                  )}
                >
                  {song.title}
                </li>
              )
            })}
        {filteredSong !== undefined && filteredSong.length === 0 && (
          <li>No song in the library</li>
        )}
      </ul>
    </React.Fragment>
  )
}
export default SongPanel
