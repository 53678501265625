export const songData = [
  {
    id: 1,
    title: "Ku Yakin Kau Hadir di sini",
    artist: "GMS",
    lyric: [
      {
        text:
          "Ku menyembah, dalam kudus hadirat-Mu\nHampiri, takhta kasih, karunia-Mu Bapa",
      },
      {
        text:
          "Kekuatanku hanya dalam hadirat-Mu\nYesusku, hanya Kaulah segala-Nya",
      },
      {
        text:
          "Kuyakin, Kau hadir disini. Kurasakan kuasa yang tak terbatas\nKuyakin, Kau nyata disini. Kemenangan terjadi disini",
      },
      {
        text:
          "Ku menyembah, dalam kudus hadirat-Mu\nHampiri, takhta kasih, karunia-Mu Bapa",
      },
      {
        text:
          "Kekuatanku hanya dalam hadirat-Mu\nYesusku, hanya Kaulah segala-Nya",
      },
      {
        text:
          "Kuyakin, Kau hadir disini. Kurasakan kuasa yang tak terbatas\nKuyakin, Kau nyata disini. Kemenangan terjadi disini",
      },
      { text: "Segnap Jiwaku memujiMu\nSungguh besar Kau Yesusku." },
      {
        text:
          "Segnap Hatiku menyembahMu\nKau Besar Kau Besar\nKau Besar Kau Besar",
      },
      {
        text:
          "Kuyakin, Kau hadir disini. Kurasakan kuasa yang tak terbatas\nKuyakin, Kau nyata disini. Kemenangan (Kesembuhan, Kelepasan) terjadi disini",
      },
    ],
  },
  {
    id: 2,
    title: "Bejana-Mu",
    artist: "JPCC Worship",
    lyric: [
      {
        text:
          "Kekuatan di jiwaku, ketenangan dibatinku\nAda dalam hadirat-Mu, kumenyembahMu",
      },
      {
        text:
          "Tersungkurku di kakimu, rasakan hadirat-Mu\nKu Takkan 'ku melepaskanMu, Kau cahaya bagiku",
      },
      {
        text:
          "MengiringMu seumur hidup, masuk dalam rencanaMu Bapa\nPikiranku, kehendakku, kuserahkan pada-Mu",
      },
      {
        text:
          "Harapanku hanya didalam-Mu, ku kan teguh, bersamaMu Tuhan\nJadikanku, bejanaMu untuk memuliakanMu",
      },
    ],
  },
  {
    id: 3,
    title: "Ajaib Kau Tuhan",
    artist: "JPCC Worship",
    lyric: [
      {
        text:
          "Tak terbayang kebesaran-Mu dan tak terbanding kekuatan-Mu Tuhan\nKaumegah bagiku",
      },
      {
        text:
          "Segenap jiwa dan pengharapan telah kuserahkan Tuk rencana-Mu Tuhan\nKau Raja atasku",
      },
      {
        text: "Hanya Kau yang layak ditinggikan",
      },
      {
        text: "Ajaib Kau Tuhan, agung dan perkasa\nKau sumber kemenanganku",
      },
      {
        text: "Layak berkuasa, memerintah selamanya\nYesus kaulah Raja mulia",
      },
    ],
  },
  {
    id: 4,
    title: "Terpujilah Nama-Mu",
    artist: "JPCC Worship",
    lyric: [
      {
        text:
          "Terpujilah Nama-Mu Tuhan\nKuat dan penuh kemuliaan\nSatu suara kami nyatakan\nKebesaran- Mu tetap selamanya",
      },
      {
        text:
          "Terpujilah Nama-Mu Tuhan\nAjaib dan penuh keagungan\nSatu hati kami nyatakan\nKerajaan-Mu kekal selamanya",
      },
      {
        text: "o-o-oh... o-o-oh",
      },
      {
        text:
          "Engkaulah Raja atas dunia\nYang menghancurkan kutuk dan membebaskan jiwa",
      },
      {
        text:
          "Kami bersorak bertepuk tangan\nEngkau Tuhan berjaya dan mem'rintah untuk s'lamanya",
      },
    ],
  },
  {
    id: 5,
    title: "Mujizat Terjadi (Curahkanlah KuasaMu Tuhan)",
    artist: "Gloria Trio",
    lyric: [
      {
        text:
          "Kurasakan kasihMu Tuhan\nKurasakan kuasaMu Tuhan\nKurasakan kasih kuasa Roh Kudus\nKurasakan kehadiranMu",
      },
      {
        text:
          "Curahkanlah kuasaMu Tuhan\nMujizat terjadi di tempat ini\nCurahkanlah kuasaMu Tuhan\nMujizat terjadi sekarang ini",
      },
    ],
  },
  {
    id: 6,
    title: "Tinggikan DiriMu",
    artist: "Sydney Mohede",
    lyric: [
      {
        text:
          "Ku bersyukur padaMu Tuhan atas KasihMu\nKu bermazmur bagi namaMu yang kudus",
      },
      {
        text:
          "Sbab Engkau Kebenaranku\nPadaMu ku percaya\nBetapa MuliaNya Tuhanku\nKu rindu Menyatakan",
      },
      {
        text:
          "Tinggikan diriMu\nMengatasi langit\nKebesaranMu Tuhan\nMengatasi bumi",
      },
      {
        text:
          "Tinggikan diriMu\nMengatasi langit\nKemuliaanMu Tuhan\nMengatasi bumi",
      },
    ],
  },
  {
    id: 7,
    title: "Sbab Kau Besar",
    artist: "",
    lyric: [
      {
        text:
          "Kuberi kemuliaan dan hormat\nKuangkat suara pujian\nKuagungkan namaMu",
      },
      {
        text:
          "Sbab Kau besar\nperbuatanMu ajaib\nTiada seperti Engkau\nTiada seperti Engkau",
      },
    ],
  },
  {
    id: 8,
    title: "Bersyukurlah (Datang ke BaitNya)",
    artist: "",
    lyric: [
      {
        text:
          "Datanglah ke baitNya\nDengan hati bersyukur\nKe dalam pelataranNya\nDengan hati bersuka",
      },
      {
        text:
          "Rasakan dan lihatlah\nBetapa baiknya Tuhan itu\nBagi yang berlindung padaNya\nAkan bersorak sorai(2x)",
      },
      {
        text:
          "Bersyukurlah kepada Tuhan\nSebab Ia baik\nBahwasanya tuk selamanya\nKasih setiaNya",
      },
    ],
  },
  {
    id: 9,
    title: "Bersyukurlah (True Worshippers)",
    artist: "True Worshippers",
    lyric: [
      {
        text: "na na na na na na...\nna na na na na na...",
      },
      {
        text:
          "Haleluya kumemujiMu Tuhan\nKar'na kasihMu tak berkesudahan\nBiar yang bernafas memuji sang Raja\nDi atas gunung dan di dalam samudra",
      },
      {
        text:
          "Tanganku kuangkat padaMu\nMensyukuri rahmat yang Kau b'ri\nKakiku melompat bagiMu\nS'bab rahmatMu baru s'tiap pagi",
      },
      {
        text:
          "Bersyukurlah kepada Tuhan\nSebab Ia baik, sebab Ia baik\nBahwasaNya untuk selamanya\nKasih setiaNya, kasih setiaNya",
      },
    ],
  },
  {
    id: 10,
    title: "Bersama-Mu",
    artist: "Sari Simorangkir",
    lyric: [
      {
        text:
          "Engkau ada bersamaku\nDi setiap musim hidupku\nTak pernah Kau biarkan Ku sendiri",
      },
      {
        text:
          "Kekuatan di jiwaku\nAdalah bersamaMu\nTak pernah kuragukan KasihMu",
      },
      {
        text:
          "BersamaMu Bapa Kulewati semua\nPerkenananMu yang Teguhkan hatiku",
      },
      {
        text:
          "Engkau yang bertindak memberi pertolongan\nAnugrahMu besar melimpah bagiku",
      },
    ],
  },
]
