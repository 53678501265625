import React, { useState } from "react"
import { songData } from "../../songData"
import { alkitabData } from "../../alkitabData"
const PreviewPanel = ({
  selectedSong,
  selectedLyric,
  setSelectedLyric,
  setFinalOutput,
  setFinalOutputLyric,
  previewMode,
  selectedScripture,
  setLiveMode,
  setLiveScripture,
  liveScripture,
  setImageBackground,
  imageBackground,
  setLiveImageBackground,
}) => {
  const handleUploadImage = e => {
    const reader = new FileReader()
    reader.onload = e => setImageBackground(e.target.result)
    reader.readAsDataURL(e.target.files[0])
  }
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "2.5rem" }}
      >
        <h2 className="h6 text-light m-0 p-0">
          Preview{" "}
          {previewMode === "song" && selectedSong !== undefined
            ? songData[selectedSong].title
            : ""}
          {previewMode === "scripture" &&
            selectedScripture !== undefined &&
            `${alkitabData[0].data[selectedScripture].judulBuku} ${alkitabData[0].data[selectedScripture].bab}:${alkitabData[0].data[selectedScripture].ayat}`}
        </h2>
        <div className="d-flex">
          <form>
            <label className="btn m-0" style={{ cursor: "pointer" }}>
              <input
                className="d-none"
                type="file"
                onChange={handleUploadImage}
              />
              Change Background
            </label>
          </form>
          {previewMode === "song" && (
            <button
              className="btn"
              onClick={() => (
                setFinalOutputLyric(0),
                setLiveMode(previewMode),
                setFinalOutput(selectedSong),
                setLiveImageBackground(imageBackground)
              )}
            >
              Go Live
            </button>
          )}
          {previewMode === "scripture" && (
            <button
              className="btn"
              onClick={() => (
                setFinalOutputLyric(0),
                setLiveMode(previewMode),
                setLiveScripture(selectedScripture),
                setLiveImageBackground(imageBackground)
              )}
            >
              Go Live
            </button>
          )}
        </div>
      </div>
      <div className="position-relative w-100" style={{ paddingTop: "56.25%" }}>
        <div
          className="position-absolute w-100 rounded p-4"
          style={{
            background: "#000000",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            overflow: "auto",
          }}
        >
          {previewMode === "song" &&
            selectedSong !== undefined &&
            songData[selectedSong].lyric.map((lyric, index) => {
              const renewedLyric = lyric.text.replace(/\n/g, "<br />")
              return (
                <p
                  className={`${selectedLyric === index && `text-light`}`}
                  onClick={() => setSelectedLyric(index)}
                  style={{ color: "#999999" }}
                  dangerouslySetInnerHTML={{
                    __html: renewedLyric,
                  }}
                ></p>
              )
            })}
          {previewMode === "scripture" && selectedScripture !== undefined && (
            <p className={`text-light`} style={{ color: "#999999" }}>
              {alkitabData[0].data[selectedScripture].teks}
            </p>
          )}
        </div>
      </div>
      <div className="my-3" />
      <div
        className="position-relative w-100"
        style={{ background: "#000000", paddingTop: "56.25%" }}
      >
        {imageBackground !== "" && (
          <img
            className="position-absolute w-100 rounded "
            style={{
              top: "0",
              left: "0",
              objectFit: "cover",
              height: "100%",
            }}
            src={imageBackground}
          />
        )}

        <div
          className="position-absolute w-100 d-flex justify-content-center align-items-center"
          style={{ top: "0", left: "0", right: "0", bottom: "0" }}
        >
          {previewMode === "song" && selectedLyric !== undefined && (
            <p
              className="text-center text-light h3"
              dangerouslySetInnerHTML={{
                __html: songData[selectedSong].lyric[
                  selectedLyric
                ].text.replace(/\n/g, "<br />"),
              }}
            ></p>
          )}
          {previewMode === "scripture" && selectedScripture !== undefined && (
            <p className="text-center text-light h3">
              {alkitabData[0].data[selectedScripture].teks}
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PreviewPanel
