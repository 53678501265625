import React from "react"
import { songData } from "../../songData"
import { alkitabData } from "../../alkitabData"
import Fullscreen from "react-full-screen"

const LiveOutputPanel = ({
  finalOutput,
  isClear,
  isLive,
  setClear,
  setLive,
  finalOutputLyric,
  setFinalOutputLyric,
  liveMode,
  liveScripture,
  liveImageBackground,
}) => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "2.5rem" }}
      >
        <h2 className="h6 text-light m-0 p-0">
          Live Output{" "}
          {liveMode === "song" &&
            finalOutput !== undefined &&
            songData[finalOutput].title}
        </h2>
        <div className="d-flex">
          <button className="btn">Logo</button>
          <button className="btn">Black</button>
          <button className="btn" onClick={() => setClear(!isClear)}>
            Clear
          </button>
          <button className="btn" onClick={() => setLive(!isLive)}>
            LIVE
          </button>
        </div>
      </div>
      <div className="position-relative w-100" style={{ paddingTop: "56.25%" }}>
        <div
          className="position-absolute w-100 rounded p-4"
          style={{
            background: "#000000",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            overflow: "auto",
          }}
        >
          {liveMode === "song" &&
            finalOutput !== undefined &&
            songData[finalOutput].lyric.map((lyric, index) => {
              const renewedLyric = lyric.text.replace(/\n/g, "<br />")
              return (
                <p
                  className={`${finalOutputLyric === index && `text-light`}`}
                  onClick={() => setFinalOutputLyric(index)}
                  style={{ color: "#999999" }}
                  dangerouslySetInnerHTML={{
                    __html: renewedLyric,
                  }}
                ></p>
              )
            })}
          {liveMode === "scripture" && liveScripture !== undefined && (
            <p className="text-light">
              {alkitabData[0].data[liveScripture].teks}
            </p>
          )}
        </div>
      </div>
      <div className="my-3" />
      <Fullscreen enabled={isLive} onChange={isFull => setLive(isFull)}>
        <div
          className="position-relative w-100"
          style={{ background: "#000000", paddingTop: "56.25%" }}
        >
          {liveImageBackground !== "" && (
            <img
              className="position-absolute w-100 rounded "
              style={{
                top: "0",
                left: "0",
                objectFit: "cover",
                height: "100%",
              }}
              src={liveImageBackground}
            />
          )}
          <div
            className="position-absolute w-100 d-flex justify-content-center align-items-center"
            style={{ top: "0", left: "0", right: "0", bottom: "0" }}
          >
            {liveMode === "song" && finalOutput !== undefined && (
              <p
                className={`text-center text-light h3 ${isClear && `d-none`}`}
                dangerouslySetInnerHTML={{
                  __html: songData[finalOutput].lyric[
                    finalOutputLyric
                  ].text.replace(/\n/g, "<br />"),
                }}
              ></p>
            )}
            {liveMode === "scripture" && liveScripture !== undefined && (
              <p className={`text-center text-light h3 ${isClear && `d-none`}`}>
                {alkitabData[0].data[liveScripture].teks}
              </p>
            )}
          </div>
        </div>
      </Fullscreen>
    </React.Fragment>
  )
}
export default LiveOutputPanel
