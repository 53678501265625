import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SongAndScripturePanel from "../components/songAndScripturePanel/songAndScripturePanel"
import { songData } from "../songData"
import PreviewPanel from "../components/previewPanel/previewPanel"
import Fullscreen from "react-full-screen"
import LiveOutputPanel from "../components/liveOutputPanel/liveOutputPanel"

const IndexPage = () => {
  const [previewMode, setPreviewMode] = useState()
  const [liveMode, setLiveMode] = useState()
  const [liveScripture, setLiveScripture] = useState()
  const [selectedSong, setSelectedSong] = useState()
  const [selectedScripture, setSelectedScripture] = useState()
  const [isLive, setLive] = useState(false)
  const [selectedLyric, setSelectedLyric] = useState()
  const [finalOutputLyric, setFinalOutputLyric] = useState(0)
  const [finalOutput, setFinalOutput] = useState()
  const [isClear, setClear] = useState(false)
  const [imageBackground, setImageBackground] = useState("")
  const [liveImageBackground, setLiveImageBackground] = useState("")
  return (
    <React.Fragment>
      <SEO title="Service" />

      <Layout>
        <div
          className="position-fixed px-2 pt-4"
          style={{
            background: "#000000",
            height: "100vh",
            width: "17vw",
            zIndex: "1",
          }}
        >
          <h1
            className="text-light font-weight-bolder font-italic my-3 text-center"
            style={{ fontSize: "1.3rem" }}
          >
            ForeverWorship
          </h1>
          <div className="my-5" />
          <SongAndScripturePanel
            onSelectSong={setSelectedSong}
            selectedSong={selectedSong}
            setSelectedLyric={setSelectedLyric}
            onSelectedScripture={setSelectedScripture}
            selectedScripture={selectedScripture}
            setPreviewMode={setPreviewMode}
          />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-2" />
            <div
              className="col-10 d-flex flex-column"
              style={{ height: "100vh" }}
            >
              <div className="py-2 my-2">
                <button className="btn">Save File</button>
              </div>
              <div className="row flex-grow-1">
                {/*Start*/}
                <div className="col-6 d-flex flex-column">
                  <PreviewPanel
                    previewMode={previewMode}
                    selectedSong={selectedSong}
                    selectedLyric={selectedLyric}
                    selectedScripture={selectedScripture}
                    setSelectedLyric={setSelectedLyric}
                    setFinalOutput={setFinalOutput}
                    setFinalOutputLyric={setFinalOutputLyric}
                    selectedScripture={selectedScripture}
                    setLiveMode={setLiveMode}
                    setLiveScripture={setLiveScripture}
                    imageBackground={imageBackground}
                    setImageBackground={setImageBackground}
                    setLiveImageBackground={setLiveImageBackground}
                  />
                </div>
                {/*end*/}
                <div className="col-6 d-flex flex-column">
                  <LiveOutputPanel
                    finalOutput={finalOutput}
                    isClear={isClear}
                    setClear={setClear}
                    isLive={isLive}
                    setLive={setLive}
                    finalOutputLyric={finalOutputLyric}
                    setFinalOutputLyric={setFinalOutputLyric}
                    liveMode={liveMode}
                    liveScripture={liveScripture}
                    setLiveScripture={setLiveScripture}
                    liveImageBackground={liveImageBackground}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
