export const alkitabData = [
  {
    id: "TB",
    versi: "Terjemahan Baru",
    data: [
      {
        judulBuku: "Kejadian",
        jenisPerjanjian: "Perjanjian Lama",
        bab: 1,
        ayat: 1,
        keyword: "Kejadian 1:1",
        judul: "Allah menciptakan langit dan bumi serta isinya",
        teks: "Pada mulanya Allah menciptakan langit dan bumi.",
      },
      {
        judulBuku: "Tobit",
        jenisPerjanjian: "Deuterokanonika",
        bab: 1,
        ayat: 1,
        keyword: "Tobit 1:1",
        judul: "Judul kitab",
        teks:
          "Kitab kisah Tobit bin Tobiel bin Ananiel bin Aduel bin Gabael bin Rafael bin Raguel dari keturunan Asiel dari suku Naftali.",
      },
      {
        judulBuku: "Matius",
        jenisPerjanjian: "Perjanjian Baru",
        bab: 1,
        ayat: 1,
        keyword: "Matius",
        judul: "Silsilah Yesus Kristus",
        teks: "Inilah silsilah Yesus Kristus, anak Daud, anak Abraham.",
      },
    ],
  },
]
